'use strict';

import $ from 'jquery';

export default class SiteHeader {
	constructor($selector, selfInit = true) {
		this.$selector = $selector;
		
		if(selfInit) this.init();
	}

	init() {
		// $('.site-header__searchbar input', this.$selector).on('keyup', (e) => {
		// 	e.preventDefault();

		// 	if (e.keyCode == 13 || e.which == 13) {
		// 		// submit search handler
    //   }
		// });

		$('.mobile-nav', this.$selector).on('click', (e) => {
			e.preventDefault();

			if(!this.$selector.hasClass('nav-active')) {
				this.openNav();
			}
			else {
				this.closeNav();
			}
		});

		$('.search-button', this.$selector).on('click', (e) => {
			e.preventDefault();

			if(!this.$selector.hasClass('search-active')) {
				this.openSearch();
			}
			else {
				this.closeSearch();
			}
		});

		$(document).on("click.searchCollapse", (e) => {
			const $eTarget = $(e.target);

			//Close off search bar when user click on area outside of site header
			if (this.$selector.hasClass("search-active")) {
				if (
					!(
						$eTarget.hasClass("site-header") ||
						$eTarget.parents(".site-header").length
					)
				) {
					this.closeSearch();
				}
			}
		});

		$('iframe').on('load', (e) => {
			const iframeDoc = e.target.contentDocument || e.target.contentWindow.document;
			$(iframeDoc).on('click', () => {
				this.closeSearch();
			});
		});

		$('.site-header__lvl1 > li', this.$selector).map((i, ele) => {
			$('> button', $(ele)).map((i,btnEle) => {
        $(btnEle).on('click', (e) => {
          e.preventDefault();

					if(!$(btnEle).parent().hasClass('active')) {
						$(btnEle).parent().addClass('active');
					}
        })
      });
		});

		$('.site-header__back', this.$selector).map((i, ele) => {
			$(ele).on('click', (e) => {
				e.preventDefault();
			
				if($(ele).parent().parent().hasClass('active')) {
					$(ele).parent().parent().removeClass('active');
					$(ele).parent().find('li.active ul').slideUp(300);
					$(ele).parent().find('li.active').removeClass('active');
				}
			});
		});

		$('.site-header__lvl2 > li > button', this.$selector).map((i, ele) => {
			$(ele).on('click', (e) => {
				e.preventDefault();
			
				if(!$(ele).parent().hasClass('active')) {
					$(ele).parent().addClass('active');
					$(ele).next().slideDown(300);
				}
				else {
					$(ele).parent().removeClass('active');
					$(ele).next().slideUp(300);
				}
			});
		});

		let timer;
		$(window).on('resize', (e) => {
			clearTimeout(timer);

			timer = setTimeout(() => {
				this.closeNav();
				if($(window).width() <= 1023) {
					this.closeSearch()
				}
			}, 300);
		});

		$(document).on('click.headerCollapse', e => {
			const $eTarget = $(e.target);

			if (!($eTarget.hasClass('site-header__nav') || $eTarget.parents('.site-header__nav').length || $eTarget.hasClass('mobile-nav') || $eTarget.parents('.mobile-nav').length || $eTarget.hasClass('site-header__searchbar') || $eTarget.parents('.site-header__searchbar').length)) {
				this.closeNav();
			}
		});
	}

	openNav() {
		this.$selector.addClass('nav-active');
	}

	closeNav() {
		this.$selector.removeClass('nav-active');
		$('.site-header__lvl2 > li.active ul').hide();
		$('li', this.$selector).removeClass('active');
	}

	openSearch() {
		this.$selector.addClass('search-active');
	}

	closeSearch() {
		this.$selector.removeClass('search-active');
	}
}